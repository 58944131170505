import useNavigation from "hooks/useNavigation";
import { useSessionStorage } from "hooks/useSessionStorage";
import React, { ReactNode, useEffect, useState } from "react";
import { NavigateOptions, useLocation } from "react-router-dom";
import { URLPaths } from "types/urls";

interface SignedInContainerProps {
  children: ReactNode;
}

const SignedInContainer = ({ children }: SignedInContainerProps) => {
  const { pathname } = useLocation();
  const { navigate } = useNavigation();
  const { getItem, removeItem } = useSessionStorage();
  const [menu] = useState([...getItem("user").menus]);

  useEffect(() => {
    // console.log(menu);
    // console.log(menu.filter((e) => e.url === pathname.substring(1)));
    if (!getItem("user")) {
      navigate(
        `/sign-in`,
        `?callback=${pathname}` as unknown as NavigateOptions,
      );
      removeItem("user");
    } else if (
      // 사용자가 권한이 없는 페이지를 url을 입력하여 이동하려고 시도했을 때 막아보려고 함.
      // 현재 로그인 했을 때 받은 user 정보를 sessionStorage에 저장해 놓고 있다.
      // 그 안에 사용자가 접근 가능한 menu의 리스트가 배열로 들어있다.
      menu.filter((e) => e.url === pathname.substring(1)).length === 0
    ) {
      navigate(`/invalid-approach` as URLPaths);
    }
  }, [getItem, removeItem, navigate, pathname, menu]);

  return <>{children}</>;
};

export default SignedInContainer;
